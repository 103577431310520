import { templates } from './MessageTemplates';

const STRING_TEMPLATES = templates;

function formatStringToCurrency(amount) {
  return Number(amount.replace(/,/g, '')).toLocaleString({
    style: 'currency',
    currency: 'PHP',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}

class Message {
  constructor(recipient, template) {
    this.recipient = recipient;
    this.template = template;
  }
}

class SikapMessage extends Message {
  constructor(recipient, template, str) {
    super(recipient, template);
    this.str = str;
  }

  getMessage() {
    return this.setMessage(this.recipient);
  }

  setMessage(recipient) {
    this.str = this.str.replace(
      '[AMOUNT]',
      formatStringToCurrency(recipient.amount)
    );
    this.str = this.str.replace(
      '[SEMESTER]-[ACADEMIC_YEAR]',
      recipient.semester + '-' + recipient.academic_year
    );
    return this.str;
  }
}

class TesMessage extends Message {
  constructor(recipient, template, str) {
    super(recipient, template);
    this.str = str;
  }

  getMessage() {
    return this.setMessage(this.recipient);
  }

  setMessage(recipient) {
    this.str = this.str.replace(
      '[AMOUNT]',
      formatStringToCurrency(recipient.amount)
    );
    this.str = this.str.replace(
      '[SEMESTER]-[ACADEMIC_YEAR]',
      recipient.semester + ' ' + recipient.academic_year
    );
    return this.str;
  }
}

class TesIndividualMessage extends Message {
  constructor(recipient, template, str) {
    super(recipient, template);
    this.str = str;
  }

  getMessage() {
    return this.setMessage(this.recipient);
  }

  setMessage(recipient) {
    this.str = this.str.replace(
      '[AMOUNT]',
      formatStringToCurrency(recipient.amount)
    );
    this.str = this.str.replace(
      '[SEMESTER]-[ACADEMIC_YEAR]',
      recipient.semester + ' ' + recipient.academic_year
    );
    return this.str;
  }
}

class TesTdpMessage extends Message {
  constructor(recipient, template, str) {
    super(recipient, template);
    this.str = str;
  }

  getMessage() {
    return this.setMessage(this.recipient);
  }

  setMessage(recipient) {
    this.str = this.str.replace(
      '[AMOUNT]',
      formatStringToCurrency(recipient.amount)
    );
    this.str = this.str.replace(
      '[SEMESTER]-[ACADEMIC_YEAR]',
      recipient.semester + ' - ' + recipient.academic_year
    );
    return this.str;
  }
}

class CspMessage extends Message {
  constructor(recipient, template, str) {
    super(recipient, template);
    this.str = str;
  }

  getMessage() {
    return this.setMessage(this.recipient);
  }

  setMessage(recipient) {
    this.str = this.str.replace(
      '[AMOUNT]',
      formatStringToCurrency(recipient.amount)
    );
    this.str = this.str.replace(
      '[SEMESTER]-[ACADEMIC_YEAR]',
      recipient.semester + ' ' + recipient.academic_year
    );
    return this.str;
  }
}

class ReimbursementMessage extends Message {
  constructor(recipient, template, str) {
    super(recipient, template);
    this.str = str;
  }

  getMessage() {
    return this.str;
  }
}

class SalaryMessage extends Message {
  constructor(recipient, template, str) {
    super(recipient, template);
    this.str = str;
  }

  getMessage() {
    return this.str;
  }
}

class BenefitsMonetizationMessage extends Message {
  constructor(recipient, template, str) {
    super(recipient, template);
    this.str = str;
  }

  getMessage() {
    return this.str;
  }
}

class BenefitsClothingAllowanceMessage extends Message {
  constructor(recipient, template, str) {
    super(recipient, template);
    this.str = str;
  }

  getMessage() {
    return this.str;
  }
}

class BenefitsYearEndBonusMessage extends Message {
  constructor(recipient, template, str) {
    super(recipient, template);
    this.str = str;
  }

  getMessage() {
    return this.str;
  }
}

class BenefitsLoadAllowanceMessage extends Message {
  constructor(recipient, template, str) {
    super(recipient, template);
    this.str = str;
  }

  getMessage() {
    return this.str;
  }
}

class RqatDepositedMessage extends Message {
  constructor(recipient, template, str) {
    super(recipient, template);
    this.str = str;
  }

  getMessage() {
    return this.str;
  }
}

class RqatCheckPickupMessage extends Message {
  constructor(recipient, template, str) {
    super(recipient, template);
    this.str = str;
  }

  getMessage() {
    return this.str;
  }
}

class LoyaltyAwardMessage extends Message {
  constructor(recipient, template, str) {
    super(recipient, template);
    this.str = str;
  }

  getMessage() {
    return this.str;
  }
}

class SalaryBonusDifferentialMessage extends Message {
  constructor(recipient, template, str) {
    super(recipient, template);
    this.str = str;
  }

  getMessage() {
    return this.str;
  }
}

function getTemplateMessage(template) {
  let temp = STRING_TEMPLATES.find((temp) => temp.name == template);
  return temp.message;
}

export default function setMessage(template, recipient) {
  const messageClasses = {
    BENEFITS_MONETIZATION: BenefitsMonetizationMessage,
    BENEFITS_CLOTHING_ALLOWANCE: BenefitsClothingAllowanceMessage,
    BENEFITS_YEAR_END_BONUS: BenefitsYearEndBonusMessage,
    BENEFITS_LOAD_ALLOWANCE: BenefitsLoadAllowanceMessage,
    CSP: CspMessage,
    TES: TesMessage,
    TES_INDIVIDUAL: TesIndividualMessage,
    TES_TDP: TesTdpMessage,
    SALARY_REG: SalaryMessage,
    SALARY_COS: SalaryMessage,
    SIKAP: SikapMessage,
    REIMBURSEMENT: ReimbursementMessage,
    RQAT_DEPOSITED: RqatDepositedMessage,
    RQAT_CHECK_PICK_UP: RqatCheckPickupMessage,
    LOYALTY_AWARD: LoyaltyAwardMessage,
    SALARY_BONUS_DIFFERENTIAL: SalaryBonusDifferentialMessage,
  };

  const MessageClass = messageClasses[template];

  if (MessageClass) {
    const message = new MessageClass(
      recipient,
      template,
      getTemplateMessage(template)
    );
    return message.getMessage();
  }
}
