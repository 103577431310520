export const templates = [
  {
    name: 'BENEFITS_CLOTHING_ALLOWANCE',
    message:
      'Your CLOTHING ALLOWANCE has been forwarded to LANDBANK. It should reflect in your account in 24-48 hours.',
  },
  {
    name: 'BENEFITS_LOAD_ALLOWANCE',
    message:
      'Your LOAD ALLOWANCE has been forwarded to LANDBANK. It should reflect in your account in 24-48 hours.',
  },
  {
    name: 'BENEFITS_MONETIZATION',
    message:
      'Your MONETIZATION has been forwarded to LANDBANK. It should reflect in your account in 24-48 hours.',
  },
  {
    name: 'BENEFITS_YEAR_END_BONUS',
    message:
      'Your YEAR-END BONUS has been forwarded to LANDBANK. It should reflect in your account in 24-48 hours.',
  },
  {
    name: 'CSP',
    message:
      'Your CMSP fund has been deposited to your bank account with the amount of [AMOUNT] for [SEMESTER]-[ACADEMIC_YEAR].',
  },
  {
    name: 'RQAT_CHECK_PICK_UP',
    message: `The check for your HONORARIUM is now ready for pick up. You can claim it at our cashier's office. Bring any valid ID.`,
  },
  {
    name: 'RQAT_DEPOSITED',
    message:
      'Your HONORARIUM has been forwarded to LANDBANK. It should reflect in your account in 24-48 hours.',
  },
  {
    name: 'REIMBURSEMENT',
    message:
      'Your REIMBURSEMENT has been forwarded to LANDBANK. It should reflect in your account in 24-48 hours.',
  },
  {
    name: 'SALARY_COS',
    message:
      'Your SALARY for this period has been forwarded to LANDBANK. It should reflect in your account in 24-48 hours.',
  },
  {
    name: 'SALARY_REG',
    message:
      'Your SALARY for this period has been forwarded to LANDBANK. It should reflect in your account in 24-48 hours.',
  },
  {
    name: 'SIKAP',
    message:
      'Your SIKAP fund has been deposited to your bank account with amount of [AMOUNT] for [SEMESTER]-[ACADEMIC_YEAR].',
  },
  {
    name: 'TES',
    message:
      'TES fund has been deposited to your bank account with the amount of [AMOUNT] for [SEMESTER]-[ACADEMIC_YEAR].',
  },
  {
    name: 'TES_INDIVIDUAL',
    message:
      'Your TES fund has been deposited to your bank account with the amount of [AMOUNT] for [SEMESTER]-[ACADEMIC_YEAR].',
  },
  {
    name: 'TES_TDP',
    message:
      'TES-TDP fund has been deposited to your bank account with the amount of [AMOUNT] for [SEMESTER]-[ACADEMIC_YEAR].',
  },
  {
    name: 'LOYALTY_AWARD',
    message:
      'Your LOYALTY AWARD has been forwarded to LANDBANK. It should reflect in your account in 24-48 hours.',
  },
  {
    name: 'SALARY_BONUS_DIFFERENTIAL',
    message:
      'Your SALARY BONUS DIFFERENTIAL has been forwarded to LANDBANK. It should reflect in your account in 24-48 hours.',
  },
];
